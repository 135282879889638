// Global styles (if we ever need them)

// Have to import variables into component stylesheet explicitly
@import 'styles/variables';

.stat-highlight {
  border-radius: 5px;
  background-color: $brand-success;
  padding: 5px 15px;
  color: white;
  font-size: 16px;
}

.tooltip-md {
  max-width: 500px;
  width: 500px;
}
.position-relative {
  position: relative;
}
