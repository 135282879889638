$bootstrap-sass-asset-helper: false !default;
$fa-font-path: "../../font-awesome/fonts/"!default;
$icon-font-path: '../../../../bootstrap-sass/assets/fonts/bootstrap/';
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #003c71 !default;
$brand-success:         #78BE20 !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;
$brand-danger-bg:       #f2dede !default;

$state-success-text:           $brand-success;
